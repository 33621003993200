import styled from 'styled-components'
import React from 'react'

function notfound() {

  return (
    <div className="notfound">
      <h1>SORRY！We cannot find the page.</h1>
      <p>The input might be wrong in some case.</p>
    </div>
  )
}

export default notfound